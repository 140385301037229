<template>
  <div id="contact">
    <MediumContainer>
      <section class="formWrapper">
        <b-field label="Name(required)">
          <b-input v-model="form.name"></b-input>
        </b-field>
        <b-field label="Phone number(required)">
          <b-input type="number" v-model="form.phone"></b-input>
        </b-field>
        <b-field label="Email(required)">
          <b-input type="email" v-model="form.mail"></b-input>
        </b-field>
        <b-field label="Inquiry(required)">
          <b-input
            v-model="form.inquiry"
            type="textarea"
            maxlength="500"
            placeholder="Please enter less than 500 characters"
          >
          </b-input>
        </b-field>
        <Button
          :disabled="!checkFormValue()"
          size="large"
          @click="ConfirmForm()"
        >
          Confirm
        </Button>
      </section>
    </MediumContainer>
  </div>
</template>

<script>
import axios from "axios";
import config from "../constants/config";
import { mapActions } from "vuex";
const endpoint = config.ENDPOINT;
export default {
  components: {},
  name: "contact",
  data() {
    return {
      form: {
        name: "",
        phone: "",
        mail: "",
        inquiry: ""
      }
    };
  },
  methods: {
    ...mapActions({
      showLoading: "Utility/showLoading",
      hideLoading: "Utility/hideLoading"
    }),
    ConfirmForm() {
      if (!this.checkFormValue()) {
        this.$buefy.toast.open({
          message: "Please complete all fields",
          type: "is-danger",
          duration: 7000
        });
        return;
      }
      this.sendForm();
    },
    checkFormValue() {
      if (
        this.form.name === "" ||
        this.form.phone === "" ||
        this.form.mail.indexOf("@") === -1 ||
        (this.form.mail === "" && this.form.inquiry === "")
      ) {
        return false;
      } else {
        return true;
      }
    },
    sendForm() {
      this.$buefy.snackbar.open({
        message:
          "If the information you entered is correct, press Send/入力された情報を確認してSendを押してください",
        // type: "is-warning",
        position: "is-top",
        actionText: "Send",
        cancelText: "Cancel",
        duration: 10000,
        onAction: () => {
          this.requestForm();
        }
      });
    },
    resteForm() {
      Object.keys(this.form).map(key => {
        this.form[key] = "";
      });
    },
    async requestForm() {
      this.showLoading();
      const URL = `${endpoint}form.php`;
      let params = new URLSearchParams();
      params.append("name", this.form.name);
      params.append("mail", this.form.mail);
      params.append("sense", this.form.phone);
      params.append("msg", this.form.inquiry);
      const res = await axios.post(URL, params).catch(err => {
        console.log(err);
        this.$buefy.toast.open(
          "エラーが発生しました。時間をおいて再度お試しください"
        );
        this.hideLoading();
      });
      this.hideLoading();
      if (res) {
        this.$buefy.toast.open({
          message: "Submitted form/送信完了しました",
          type: "is-success"
        });
        this.resteForm();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styls/variables.scss";
#contact {
  margin: setSingleMargin(2) setSingleMargin(1);
  .formWrapper {
    text-align: left;
  }
}
</style>
